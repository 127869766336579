<template>
  <div id="profile">
    <AuthChecker
      v-if="this.$route.path !== '/project/projectsList'"
    ></AuthChecker>
    <div id="profilenav">
      <Navbar v-if="user && user.role.name === 'admin'"></Navbar>
      <ProfileNavbar v-else class="AdaptiveForNewNavbar" />
    </div>
    <!-- <Ask
      :email="this.user !== null ? this.user.email : null"
      :institute="this.user !== null ? this.user.university : null"
    ></Ask> -->
    <router-view />

    <Transition name="fade">
      <footer v-if="showFooter">
        <div class="footer container">
          <div class="row">
            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 class="text-uppercase">Практика в "Форус"</h5>
              <p>
                Пройди практику в одной из крупнейших IT-компаний Сибири и
                Дальнего Востока. Напиши диплом вместе в нами. Начни свою
                карьеру. Мы открыты для новых возможностей и всегда готовы
                прийти на помощь. Ждем именно тебя!
              </p>
            </div>

            <div class="col-lg-3 col-md-6 mb-4 mb-md-0"></div>

            <div class="col-lg-3 col-md-12 mb-4 mb-md-0">
              <h5 id="contacts" class="text-uppercase">Контакты</h5>

              <ul class="list-unstyled">
                <li>
                  <i class="fas fa-phone fa-lg"></i
                  ><span> +7 (3952) 25-87-33</span>
                </li>
                <li>
                  <i class="fas fa-map-marker-alt fa-lg"></i
                  ><span> г. Иркутск, ул. Партизанская 49</span>
                </li>
                <li>
                  <i class="fas fa-envelope-open-text fa-lg"></i>
                  <a class="mail-link" href="students.forus@mail.ru"
                    >students.forus@mail.ru</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="copyright text-center">Forus © 1992-2024</div>
      </footer>
    </Transition>
  </div>
</template>

<script>
import AuthChecker from "@/components/CheckUserAuthorized";

import { mapGetters, mapMutations } from "vuex";
import Navbar from "@/components/Profile/Navbar.vue";
import ProfileNavbar from "@/components/Navbar/NavbarCombined.vue";
// import Ask from "@/components/Ask.vue";
export default {
  data() {
    return {
      showFooter: false,
    };
  },
  components: {
    Navbar,
    // Ask,
    AuthChecker,
    ProfileNavbar,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  mounted() {
    setTimeout(() => {
      this.showFooter = true;
    }, 600);
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"); */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");

h3 {
  font-family: "Montserrat";
  font-weight: 600 !important;
  color: #395f76 !important;
}

#profile {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style scoped>
.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 300 !important;
}
footer {
  background-color: #32353a !important;
  color: white !important;
  padding-top: 40px;
  margin-top: 10vh;
  font-family: "Montserrat";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.AdaptiveForNewNavbar {
  padding: 50px 120px 0px 120px;
}

@media (max-width: 480px) {
  .AdaptiveForNewNavbar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 20px 0px 20px;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .AdaptiveForNewNavbar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 40px 0px 40px;
  }
}
</style>
